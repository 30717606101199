import React, { Component } from "react";
import moment from "moment";
import { message } from "flwww";
import { db } from "../Firebase/firebase";
import { Save, Plus, X } from "react-feather";
import config from "../../config";

export default class Timings extends Component {
  state = {
    weekDay: {
      opening: moment({ hour: 10, minute: 0 }),
      closing: moment({ hour: 22, minute: 0 }),
      selected: [],
      currentTimings: [],
    },
    weekEnd: {
      opening: moment({ hour: 9, minute: 0 }),
      closing: moment({ hour: 22, minute: 0 }),
      selected: [],
      currentTimings: [],
    },
    timeStep: 30,
  };

  componentDidMount() {
    let weekDay = config.outlet === "TCS" ? "weekday" : "weekdayCMPB";
    let weekEnd = config.outlet === "TCS" ? "weekend" : "weekendCMPB";
    db.collection("Timings")
      .doc(weekDay)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("exist");
          let { opening, closing, ranges } = doc.data();
          console.log(ranges);
          let selected = [];
          if (ranges.length !== 0) {
            ranges = ranges.map((range) => {
              range.start = moment(range.start.toDate());
              range.end = moment(range.end.toDate());
              return range;
            });
            ranges.map((range) => {
              selected = [
                ...selected,
                ...this.generateSlots(range.start, range.end, range.profile),
              ];
            });
          }
          this.setState({
            weekDay: {
              opening: moment({ hour: opening.hour, minute: opening.minute }),
              closing: moment({ hour: closing.hour, minute: closing.minute }),
              selected,
              currentTimings: ranges,
            },
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    db.collection("Timings")
      .doc(weekEnd)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("exist");
          let { opening, closing, ranges } = doc.data();
          console.log(ranges);
          let selected = [];
          if (ranges.length !== 0) {
            ranges = ranges.map((range) => {
              range.start = moment(range.start.toDate());
              range.end = moment(range.end.toDate());
              return range;
            });
            ranges.map((range) => {
              selected = [
                ...selected,
                ...this.generateSlots(range.start, range.end, range.profile),
              ];
            });
          }
          this.setState({
            weekEnd: {
              opening: moment({ hour: opening.hour, minute: opening.minute }),
              closing: moment({ hour: closing.hour, minute: closing.minute }),
              selected,
              currentTimings: ranges,
            },
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  generateSlots = (start, stop, profile) => {
    let { timeStep } = this.state;
    let slots = [];
    let currentSlot = {
      start: start,
      end: start.clone().add(timeStep, "m"),
      label: `${start.format("HH:mm")} - ${start
        .clone()
        .add(timeStep, "m")
        .format("HH:mm")}`,
      profile: profile,
    };
    while (
      currentSlot.end.hour() * 100 + currentSlot.end.minute() <=
      stop.hour() * 100 + stop.minute()
    ) {
      let newSlot = { ...currentSlot };
      slots.push(newSlot);
      let nextSlot = { ...newSlot };
      nextSlot.start = nextSlot.end;
      nextSlot.end = nextSlot.start.clone().add(timeStep, "m");
      nextSlot.label = `${nextSlot.start.format(
        "HH:mm"
      )} - ${nextSlot.end.format("HH:mm")}`;
      currentSlot = nextSlot;
    }
    return slots;
  };

  getRanges = (day, docid) => {
    let finalRanges = [];
    let currentRange = [];
    let { selected } = this.state[day];
    if (selected.length === 1) {
      this.setState({ currentTimings: selected });
      return;
    }
    selected = Array.from(new Set(selected));
    selected
      .sort(
        (a, b) =>
          a.start.hour() * 100 +
          a.start.minute() -
          (b.start.hour() * 100 + b.start.minute())
      )
      .map((slot, index) => {
        if (index !== selected.length - 1) {
          if (
            slot.end.format("HH:mm") ==
            selected[index + 1].start.format("HH:mm")
          ) {
            if (slot.profile === selected[index + 1].profile) {
              currentRange.push(slot);
            } else {
              if (currentRange.length !== 0) {
                currentRange.push(slot);
                finalRanges.push({
                  label: `${currentRange[0].start.format(
                    "HH:mm"
                  )} - ${currentRange[currentRange.length - 1].end.format(
                    "HH:mm"
                  )}`,
                  start: currentRange[0].start,
                  end: currentRange[currentRange.length - 1].end,
                  profile: currentRange[0].profile,
                });
                currentRange = [];
              } else {
                finalRanges.push(slot);
              }
            }
          } else {
            if (currentRange.length !== 0) {
              currentRange.push(slot);
              finalRanges.push({
                label: `${currentRange[0].start.format(
                  "HH:mm"
                )} - ${currentRange[currentRange.length - 1].end.format(
                  "HH:mm"
                )}`,
                start: currentRange[0].start,
                end: currentRange[currentRange.length - 1].end,
                profile: currentRange[0].profile,
              });
              currentRange = [];
            } else {
              finalRanges.push(slot);
            }
          }
        } else {
          if (
            slot.start.format("HH:mm") ===
            selected[index - 1].end.format("HH:mm")
          ) {
            if (slot.profile === selected[index - 1].profile) {
              currentRange.push(slot);
              finalRanges.push({
                label: `${currentRange[0].start.format(
                  "HH:mm"
                )} - ${currentRange[currentRange.length - 1].end.format(
                  "HH:mm"
                )}`,
                start: currentRange[0].start,
                end: currentRange[currentRange.length - 1].end,
                profile: currentRange[0].profile,
              });
              currentRange = [];
            } else {
              if (currentRange.length !== 0) {
                finalRanges.push({
                  label: `${currentRange[0].start.format(
                    "HH:mm"
                  )} - ${currentRange[currentRange.length - 1].end.format(
                    "HH:mm"
                  )}`,
                  start: currentRange[0].start,
                  end: currentRange[currentRange.length - 1].end,
                  profile: currentRange[0].profile,
                });
                currentRange = [];
              } else {
                finalRanges.push(slot);
              }
            }
          } else {
            if (currentRange.length !== 0) {
              finalRanges.push({
                label: `${currentRange[0].start.format(
                  "HH:mm"
                )} - ${currentRange[currentRange.length - 1].end.format(
                  "HH:mm"
                )}`,
                start: currentRange[0].start,
                end: currentRange[currentRange.length - 1].end,
                profile: currentRange[0].profile,
              });
              currentRange = [];
            } else {
              finalRanges.push(slot);
            }
          }
        }
      });
    this.setState(
      {
        [day]: {
          ...this.state[day],
          currentTimings: Array.from(new Set(finalRanges)),
        },
      },
      () => {
        let newRanges = [...this.state[day].currentTimings];
        newRanges = newRanges.map((range) => {
          range.start = range.start.toDate();
          range.end = range.end.toDate();
          return range;
        });
        db.collection("Timings").doc(docid).update({
          ranges: newRanges,
        });
        message("Changes Saved!", "success", 4);
      }
    );
  };
  render() {
    let { weekDay, weekEnd } = this.state;
    let weekDaySlots = this.generateSlots(weekDay.opening, weekDay.closing, "");
    let weekEndSlots = this.generateSlots(weekEnd.opening, weekEnd.closing, "");
    let { profileData, profile } = this.props;
    console.log(profileData);
    return (
      <div id="timings">
        <div className="timing-header">
          <h3>Weekday</h3>{" "}
          <button onClick={() => this.getRanges("weekDay", "weekday")}>
            <Save></Save>Save
          </button>{" "}
        </div>
        <div className="timeslots">
          {weekDaySlots.map((slot) => {
            return (
              <button
                style={
                  weekDay.selected.some((item) => item.label === slot.label)
                    ? {
                        backgroundColor: `${
                          profileData.find(
                            (profileInfo) =>
                              profileInfo.id ===
                              weekDay.selected.find(
                                (item) => item.label === slot.label
                              ).profile
                          )
                            ? profileData.find(
                                (profileInfo) =>
                                  profileInfo.id ===
                                  weekDay.selected.find(
                                    (item) => item.label === slot.label
                                  ).profile
                              ).profileColor
                            : "none"
                        }`,
                      }
                    : {}
                }
                onClick={() => {
                  let slotIndex = weekDay.selected.findIndex(
                    (element) => element.label === slot.label
                  );
                  if (slotIndex === -1) {
                    this.setState({
                      weekDay: {
                        ...weekDay,
                        selected: [
                          ...weekDay.selected,
                          { ...slot, profile: profile.id },
                        ],
                      },
                    });
                  } else {
                    let newList = [...weekDay.selected];
                    newList.splice(
                      newList.findIndex(
                        (element) => element.label === slot.label
                      ),
                      1
                    );
                    this.setState({
                      weekDay: { ...weekDay, selected: newList },
                    });
                  }
                }}>
                {slot.label}
              </button>
            );
          })}
        </div>

        <div className="timing-header">
          <h3>Weekend</h3>{" "}
          <button onClick={() => this.getRanges("weekEnd", "weekend")}>
            <Save></Save>Save
          </button>{" "}
        </div>
        <div className="timeslots">
          {weekEndSlots.map((slot) => {
            return (
              <button
                style={
                  weekEnd.selected.some((item) => item.label === slot.label)
                    ? {
                        backgroundColor: `${
                          profileData.find(
                            (profileInfo) =>
                              profileInfo.id ===
                              weekEnd.selected.find(
                                (item) => item.label === slot.label
                              ).profile
                          )
                            ? profileData.find(
                                (profileInfo) =>
                                  profileInfo.id ===
                                  weekEnd.selected.find(
                                    (item) => item.label === slot.label
                                  ).profile
                              ).profileColor
                            : "none"
                        }`,
                      }
                    : {}
                }
                onClick={() => {
                  let slotIndex = weekEnd.selected.findIndex(
                    (element) => element.label === slot.label
                  );
                  if (slotIndex === -1) {
                    this.setState({
                      weekEnd: {
                        ...weekEnd,
                        selected: [
                          ...weekEnd.selected,
                          { ...slot, profile: profile.id },
                        ],
                      },
                    });
                  } else {
                    let newList = [...weekEnd.selected];
                    newList.splice(
                      newList.findIndex(
                        (element) => element.label === slot.label
                      ),
                      1
                    );
                    this.setState({
                      weekEnd: { ...weekEnd, selected: newList },
                    });
                  }
                }}>
                {slot.label}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}
