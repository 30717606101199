import React, { Component } from "react";
import { db, storage } from "../Firebase/firebase";
import { message, DefaultProgress } from "flwww";
import { motion } from "framer-motion";
import {
  Trash2,
  Upload,
  UploadCloud,
  Edit2,
  X,
  PlusCircle,
} from "react-feather";
import OptionsEdit from "./OptionsEdit";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: null,
      url: "",
      options: [],
      createOption: false,
      editOption: false,
      available: true,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  componentDidMount() {
    console.log("sss");
    if (this.props.itemId) {
      db.collection("Menu")
        .doc(this.props.itemId)
        .get()
        .then((doc) => {
          this.setState({
            ...doc.data(),
          });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemId !== this.props.itemId) {
      this.setState(
        { url: "", options: [], createOption: false, editOption: false },
        () => {
          delete this.state.desc;
          delete this.state.pax;
          db.collection("Menu")
            .doc(this.props.itemId)
            .get()
            .then((doc) => {
              this.setState({
                ...doc.data(),
              });
            });
        }
      );
    }
  }

  onChange = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.id]: e.target.value });
  };

  onDelete = () => {
    db.collection("menu")
      .doc(this.props.itemId)
      .delete()
      .then(function () {
        message("Item Deleted!", "success", 4);
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  };

  onBoolChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "true") {
      this.setState({ [e.target.id]: true });
    } else {
      this.setState({ [e.target.id]: false });
    }
  };

  onPriceChange = (e) => {
    this.setState({ [e.target.id]: parseInt(e.target.value) });
  };

  handleFileChange = (e) => {
    if (e.target.files[0]) {
      const imageFile = e.target.files[0];
      this.setState({ imageFile });
    }
  };

  addOption = (option) => {
    let currentOptions = [...this.state.options];
    currentOptions.push(option);
    this.setState({
      options: currentOptions,
      createOption: false,
      editOption: false,
    });
  };

  updateOption = (option) => {
    //todo
    let { options, currentOption } = this.state;
    let newOptions = [...options];
    let optionToReplaceIndex = options.indexOf(currentOption);
    if (optionToReplaceIndex !== -1) {
      newOptions.splice(optionToReplaceIndex, 1, option);
      this.setState({ options: newOptions });
      message("Option Changes Saved!", "success", 4);
    } else {
      return;
    }
  };

  handleUpload = (e) => {
    e.preventDefault();
    const { imageFile } = this.state;
    const uploadTask = storage.ref(`images/${imageFile.name}`).put(imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //progress functions
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(imageFile.name)
          .getDownloadURL()
          .then((url) => {
            // Insert url into an <img> tag to "download"
            this.setState({ url, progress: null });
            message("Upload Success!", "success", 4);
          });
      }
    );
  };

  submitEdit = (e) => {
    e.preventDefault();
    delete this.state.imageFile;
    message("Edit Success!", "success", 4);
    db.collection("Menu").doc(this.props.itemId).update(this.state);
  };
  render() {
    console.log(this.state);
    console.log(this.props.itemId);
    let {
      name,
      description,
      category,
      priced,
      pricet,
      available,
      progress,
      options,
      createOption,
      editOption,
      currentOption,
    } = this.state;
    if (!this.state.options || this.state.options === "optnil") {
      this.setState({ options: [] });
    }
    return (
      <div className="edit-box">
        <div className="item-form">
          <div className="item-info">
            <div className="item-details">
              <img
                src={
                  this.state.url ||
                  "https://calgorix.com/wp-content/uploads/2018/11/placeholder.png"
                }
                alt=""
              />
              <div className="form-group">
                <div className="file-form-group">
                  <input type="file" onChange={this.handleFileChange} />
                  <button onClick={this.handleUpload}>
                    <Upload></Upload> Upload
                  </button>
                </div>
                {progress ? (
                  <DefaultProgress prcnt={progress}></DefaultProgress>
                ) : null}
              </div>
              <hr />
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="pricet">Self Collection Price</label>
                <input
                  type="number"
                  id="pricet"
                  value={pricet}
                  onChange={this.onPriceChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="priced">Delivery Price</label>
                <input
                  type="number"
                  id="priced"
                  value={priced}
                  onChange={this.onPriceChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Category</label>
                <select value={category} id="category" onChange={this.onChange}>
                  {this.props.categories.map((cat) => (
                    <option value={cat.name}>{cat.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Availability</label>
                <select
                  id="available"
                  value={available}
                  onChange={this.onBoolChange}>
                  <option value={true}>ENABLED</option>
                  <option value={false}>DISABLED</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  id="description"
                  value={description}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="item-settings">
              <div className="item-options">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <h2>Options</h2>
                  {editOption || createOption ? (
                    <motion.button
                      onClick={() =>
                        this.setState({
                          createOption: false,
                          editOption: false,
                        })
                      }>
                      Back To Options
                    </motion.button>
                  ) : (
                    <motion.button
                      onClick={() =>
                        this.setState({ createOption: true, editOption: false })
                      }>
                      New Option
                    </motion.button>
                  )}
                </div>
                <hr />
                {createOption ? (
                  <OptionsEdit
                    addOption={this.addOption}
                    edit={false}></OptionsEdit>
                ) : editOption ? (
                  <OptionsEdit
                    updateOption={this.updateOption}
                    edit={true}
                    data={currentOption}></OptionsEdit>
                ) : (
                  <div className="item-options-list">
                    {options.length !== 0
                      ? options.map((option) => {
                          return (
                            <div>
                              <span>{option.label}</span>
                              <Edit2
                                onClick={() =>
                                  this.setState({
                                    currentOption: option,
                                    editOption: true,
                                  })
                                }></Edit2>
                            </div>
                          );
                        })
                      : null}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="buttons">
            <motion.button
              whileHover={{
                backgroundColor: "rgb(59, 177, 104)",
                color: "#fff",
              }}
              whileTap={{ scale: 0.9 }}
              className="save-btn"
              onClick={this.submitEdit}>
              <UploadCloud></UploadCloud> Save
            </motion.button>
            <motion.button
              whileHover={{ backgroundColor: "#d40e40", color: "#fff" }}
              whileTap={{ scale: 0.9 }}
              style={{
                backgroundColor: "#fff",
                color: "#d40e40",
                border: "2px solid #d40e40",
              }}
              onClick={this.onDelete}>
              <Trash2></Trash2> Delete
            </motion.button>
          </div>
        </div>
      </div>
    );
  }
}
